import React, { useState, useEffect } from 'react';

const FilterModal = ({ isOpen, onClose, onApply, initialFilters }) => {
    const [activeCategory, setActiveCategory] = useState('');
    const [filterOptions, setFilterOptions] = useState({
        updatedOn: initialFilters.updatedOn || '',
        categories: initialFilters.categories || [], // Changed to an array for multiple categories
        productTypes: initialFilters.productTypes || [], // Changed to an array for multiple product types
        dateType: initialFilters.dateType || 'specific',
        prescriptionStatus: initialFilters.prescriptionStatus || '', // New field
        onlineSellStatus: initialFilters.onlineSellStatus || '',
    });

    const categories = [
        'Pain Relief', 'Skin Care', 'Cold & Flu Remedies', 
        'Digestive Health', 'Vitamins & Supplements', 
        'Allergy & Sinus Relief', 'Baby Care', 
        'Drinks & Supplements', 'Glocumeters & Test Strips', 
        'Women Care', 'Health Devices', 'Ayurveda', 
        'Personal Care', 'Sexual Wellness', 'Other'
    ];

    const productTypes = [
        'bottles','capsules','captabs','creams','drops',
        'facewash','gels','implants','infusions','injections',
        'instacaps','kits','lotions','lozenges','MDI',
        'ointments','paste','patches','powders','soaps',
        'solutions','sprays','strips','suppositories',
        'suspensions','syrup','syrups','tabcaps','tablets'
    ];

    const hasSelectedFilters = (key) => {
        if (key === 'categories') return filterOptions.categories.length > 0;
        if (key === 'productTypes') return filterOptions.productTypes.length > 0;
        if (key === 'prescriptionStatus' || key === 'onlineSellStatus') return filterOptions[key] !== '';
        return filterOptions[key] && filterOptions[key].trim() !== '';
    };

    const toggleCategorySelection = (category) => {
        setFilterOptions((prev) => {
            const isSelected = prev.categories.includes(category);
            return {
                ...prev,
                categories: isSelected 
                    ? prev.categories.filter((c) => c !== category) 
                    : [...prev.categories, category],
            };
        });
    };

    const handleFilterChange = (key, value) => {
        setFilterOptions((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const toggleProductTypeSelection = (productType) => {
        setFilterOptions((prev) => {
            const isSelected = prev.productTypes.includes(productType);
            return {
                ...prev,
                productTypes: isSelected 
                    ? prev.productTypes.filter((p) => p !== productType) 
                    : [...prev.productTypes, productType],
            };
        });
    };

    const handleReset = () => {
        const resetFilters = {
            updatedOn: '',
            categories: [],
            productTypes: [],
            dateType: 'specific',
            prescriptionStatus: '',
            onlineSellStatus: '',
        };
        setFilterOptions(resetFilters);
        onApply(resetFilters);
        onClose();
    };

    useEffect(() => {
        setActiveCategory('updatedOn');
    }, []);

    const handleApply = () => {
        onApply(filterOptions);
        onClose();
    };

    return (
        <div className={`fixed top-0 right-0 bottom-0 text-gray-700 bg-white shadow-lg w-[36rem] transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300`}>
            {/* Close Icon */}
            <button className="absolute text-2xl top-4 right-4 text-gray-500 hover:text-gray-700" onClick={onClose}> ✕ </button>
            <h2 className="text-xl px-6 py-6">Filters</h2>
            <div className="flex h-full">
                {/* Left Sidebar */}
                <div className="w-1/3 bg-white p-4 px-0 border-r flex flex-col text-gray-700">
                    <ul className="space-y-3">
                        {['updatedOn', 'categories', 'productTypes', 'prescriptionStatus', 'onlineSellStatus'].map((key) => (
                            <li key={key} className={`flex items-center cursor-pointer p-2 py-4 pl-4 border-b-[1px] ${activeCategory === key ? 'text-cyan-600 font-normal' : 'font-thin'}`} onClick={() => setActiveCategory(key)}>
                                <div className="flex justify-between items-center w-full pl-2">
                                    <span>
                                        {key === 'updatedOn' ? 'Updated On'
                                            : key === 'categories' ? 'Categories'
                                            : key === 'productTypes' ? 'Product Types'
                                            : key === 'prescriptionStatus' ? 'Prescription Status'
                                            : 'Online Sell Status'}
                                    </span>
                                    <span className={`w-2 h-2 rounded-full mr-2 ${hasSelectedFilters(key) ? 'bg-cyan-600' : 'bg-white'}`} />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* Right Content */}
                <div className="w-2/3 px-6 flex flex-col justify-between">
                    <div>
                        {activeCategory === 'updatedOn' && (
                            <div>
                                <label className="block text-sm font-medium mb-4">Updated On</label>
                                <div className="flex items-center gap-4">
                                    <select value={filterOptions.dateType} onChange={(e) => handleFilterChange('dateType', e.target.value)} className="border rounded px-2 py-1 text-sm">
                                        <option value="specific">Specific Date</option>
                                        <option value="before">Before</option>
                                    </select>
                                    <input type="date" value={filterOptions.updatedOn} onChange={(e) => handleFilterChange('updatedOn', e.target.value)} className="border rounded px-2 py-1 text-sm" />
                                </div>
                            </div>
                        )}
                        {activeCategory === 'categories' && (
                            <div>
                                <label className="block text-sm font-medium mb-4">Categories</label>
                                <div className="overflow-y-scroll max-h-[34rem]">
                                {categories.map((category) => (
                                    <div key={category} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            className="w-4 h-4"
                                            checked={filterOptions.categories.includes(category)}
                                            onChange={() => toggleCategorySelection(category)}
                                            id={category}
                                        />
                                        <label htmlFor={category} className="ml-2">{category}</label>
                                    </div>
                                ))}
                                </div>
                            </div>
                        )}
                        {activeCategory === 'productTypes' && (
                            <div>
                                <label className="block text-sm font-medium mb-4">Product Types</label>
                                <div className="overflow-y-scroll max-h-[34rem]">
                                {productTypes.map((type) => (
                                    <div key={type} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            className="w-4 h-4"
                                            checked={filterOptions.productTypes.includes(type)}
                                            onChange={() => toggleProductTypeSelection(type)}
                                            id={type}
                                        />
                                        <label htmlFor={type} className="ml-2">{type}</label>
                                    </div>
                                ))}
                                </div>
                            </div>
                        )}
                        {activeCategory === 'prescriptionStatus' && (
                            <div>
                                <label className="block text-sm font-medium mb-4">Prescription Status</label>
                                <select value={filterOptions.prescriptionStatus} onChange={(e) => handleFilterChange('prescriptionStatus', e.target.value)} className="border rounded px-2 py-1 text-sm w-full">
                                    <option value="">Select</option>
                                    <option value="Required">Required</option>
                                    <option value="Not Required">Not Required</option>
                                </select>
                            </div>
                        )}
                        {activeCategory === 'onlineSellStatus' && (
                            <div>
                                <label className="block text-sm font-medium mb-4">Online Sell Status</label>
                                <select value={filterOptions.onlineSellStatus} onChange={(e) => handleFilterChange('onlineSellStatus', e.target.value)} className="border rounded px-2 py-1 text-sm w-full">
                                    <option value="">Select</option>
                                    <option value="Available">Available</option>
                                    <option value="Not Available">Not Available</option>
                                </select>
                            </div>
                        )}
                    </div>
                    {/* Buttons at Bottom */}
                    <div className="absolute bottom-0 mb-4 flex justify-between gap-6">
                        <button onClick={handleReset} className="px-4 py-2 bg-gray-300 rounded text-sm"> Reset </button>
                        <button onClick={handleApply} className="px-4 py-2 bg-blue-500 text-white rounded text-sm"> Apply </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterModal;
