import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PendingApprovals = () => {
    const navigate = useNavigate();
    const [applicationData, setApplicationData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedApplicationId, setSelectedApplicationId] = useState(null);
    const [rejectionReason, setRejectionReason] = useState('');
    const [mistakeStage, setMistakeStage] = useState('');
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [collapsedCards, setCollapsedCards] = useState({});

    const mistakeStages = [
        { label: 'Store Details', value: 1 },
        { label: 'Contact Info', value: 2 }
    ];

    useEffect(() => {
        const adminId = JSON.parse(sessionStorage.getItem('adminId'));
        if (!adminId) {
            navigate('/admin-login');
        }
    }, []);

    useEffect(() => {
        fetchPendingApprovals();
    }, []);

    const fetchPendingApprovals = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/pending-applications`);
            if (response.status === 200) {
                setApplicationData(response.data);
            } else if (response.status === 202) {
                console.log("No new application found");
            } else {
                console.error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const toggleCard = (id) => {
        setCollapsedCards(prevState => ({
            ...prevState,
            [id]: !prevState[id] // Toggle the collapsed state for the clicked card
        }));
    };

    const approveApplication = async (id) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/approve-application/${id}`);
            if (response.status === 200) {
                toast.success('Application approved successfully!', { position: 'top-right' });
                fetchPendingApprovals();
            } else {
                toast.error('Failed to approve application.', { position: 'top-right' });
            }
        } catch (error) {
            toast.error('Failed to approve application.', { position: 'top-right' });
        }
    };

    const rejectApplication = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/reject-application/${selectedApplicationId}`, {
                reason: rejectionReason,
                stage: mistakeStage,
            });
            if (response.status === 200) {
                toast.success('Application rejected successfully!', { position: 'top-right' });
                fetchPendingApprovals();
                handleCloseModal();
            } else {
                toast.error('Failed to reject application.', { position: 'top-right' });
            }
        } catch (error) {
            toast.error('Failed to reject application.', { position: 'top-right' });
        }
    };

    const handleOpenModal = (id) => {
        setSelectedApplicationId(id);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setRejectionReason('');
        setMistakeStage('');
    };

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setImageModalOpen(true);
    };

    const handleCloseImageModal = () => {
        setImageModalOpen(false);
        setSelectedImage('');
    };

    return (
        <div className="verify-section w-full h-full">
            {applicationData.length > 0 ? (
                <div className="py-8 md:p-2 mx-8 text-gray-500">
                    <div className=''>Total Applications: <span className="text-lg">{applicationData.length}</span></div>
                    {applicationData.map((item) => (
                        <div key={item._id} className="bg-white p-2 my-4 shadow-md border-[1px] border-gray-100 rounded-md">
                            <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleCard(item._id)}>
                                <div className="text-base font-semibold mb-2">
                                    Name: <span className="text-xl font-bold">{item.storeName}</span>
                                </div>
                                <div className="cursor-pointer">
                                    {collapsedCards[item._id] ? <FaChevronUp /> : <FaChevronDown />} {/* Toggle Chevron icon */}
                                </div>
                            </div>
                            {!collapsedCards[item._id] && (
                                <>
                                <hr />
                                <div className="mt-2 mb-4">
                                    <h3 className="font-semibold bg-slate-100 px-2 py-1">Store Details</h3>
                                    <div className="w-full flex flex-row text-sm items-center px-2 mt-2">
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">Owner Name</div>
                                            <div>{item.ownerName}</div>
                                        </div>
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">GSTIN</div>
                                            <div>{item.gstin}</div>
                                        </div>
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">License Number</div>
                                            <div>{item.drugLicenseNumber}</div>
                                        </div>
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">License Photo</div>
                                            <div>
                                                {item.drugLicenseImg ? (
                                                    <img
                                                        src={item.drugLicenseImg}
                                                        alt="License"
                                                        className="w-7 h-7 rounded-md shadow-sm cursor-pointer"
                                                        onClick={() => handleImageClick(item.drugLicenseImg)}
                                                    />
                                                ) : (
                                                    <div className="text-gray-500">N/A</div>
                                                )}
                                            </div>
                                        </div>  
                                    </div>
                                    <div className="w-full flex flex-row text-sm items-center px-2 mt-2">
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">Latitude</div>
                                            <div>{item.storeLatitude}</div>
                                        </div>
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">Longitude</div>
                                            <div>{item.storeLongitude}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <h3 className="font-semibold bg-slate-100 px-2 py-1">Contact Info</h3>
                                    <div className="w-full flex flex-row text-sm items-center px-2 mt-2">
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">Owner Name</div>
                                            <div>{item.ownerName}</div>
                                        </div>
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">Email</div>
                                            <div>{item.email}</div>
                                        </div>
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">Mobile</div>
                                            <div>{item.mobileNumber}</div>
                                        </div>
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">ID Proof</div>
                                            <div>
                                                {item.ownerIDProofImg ? (
                                                    <img
                                                        src={item.ownerIDProofImg}
                                                        alt="ID Proof"
                                                        className="w-7 h-7 rounded-md shadow-sm cursor-pointer"
                                                        onClick={() => handleImageClick(item.ownerIDProofImg)}
                                                    />
                                                ) : (
                                                    <div className="text-gray-500">N/A</div>
                                                )}
                                            </div>
                                        </div>  
                                    </div>
                                    <div className="w-full flex flex-row text-sm items-center px-2 mt-2">
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">State</div>
                                            <div>{item.state}</div>
                                        </div>
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">City</div>
                                            <div>{item.city}</div>
                                        </div>
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">Store Image (Outside)</div>
                                            <div>
                                                {item.storeOutsideImg ? (
                                                    <img
                                                        src={item.storeOutsideImg}
                                                        alt="License"
                                                        className="w-7 h-7 rounded-md shadow-sm cursor-pointer"
                                                        onClick={() => handleImageClick(item.storeOutsideImg)}
                                                    />
                                                ) : (
                                                    <div className="text-gray-500">N/A</div>
                                                )}
                                            </div>
                                        </div> 
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">Store Image (Inside)</div>
                                            <div>
                                                {item.storeInsideImg ? (
                                                    <img
                                                        src={item.storeInsideImg}
                                                        alt="License"
                                                        className="w-7 h-7 rounded-md shadow-sm cursor-pointer"
                                                        onClick={() => handleImageClick(item.storeInsideImg)}
                                                    />
                                                ) : (
                                                    <div className="text-gray-500">N/A</div>
                                                )}
                                            </div>
                                        </div>  
                                    </div>
                                    <div className="w-full flex flex-row text-sm items-center px-2 mt-2">
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">Zipcode</div>
                                            <div>{item.zipCode}</div>
                                        </div>
                                        <div className="w-3/12 flex flex-col">
                                            <div className="font-semibold">Landmark</div>
                                            <div>{item.landmark}</div>
                                        </div>
                                        <div className="w-6/12 flex flex-col">
                                            <div className="font-semibold">Full Address</div>
                                            <div>{item.fullAddress}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full flex justify-end px-4 gap-4 mt-4 mb-4">
                                    <button className="bg-red-500 hover:bg-red-600 text-white px-4 py-1 font-semibold rounded-sm shadow-md cursor-pointer" onClick={() => handleOpenModal(item._id)}>Reject</button>
                                    <button className="bg-green-500 hover:bg-green-600 text-white px-4 py-1 font-semibold rounded-sm shadow-md cursor-pointer" onClick={() => approveApplication(item._id)}>Approve</button>
                                </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <div className="w-full text-center font-semibold text-3xl mt-12 text-gray-500">No application pending</div>
            )}

            {openModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
                        <h2 className="text-xl font-semibold mb-4">Reject Application</h2>
                        <div className="mb-4">
                            <label htmlFor="mistake-stage" className="block text-sm font-medium text-gray-700 mb-1">Mistake Stage</label>
                            <select
                                id="mistake-stage"
                                className="w-full border rounded-md p-2"
                                value={mistakeStage}
                                onChange={(e) => setMistakeStage(e.target.value)}
                            >
                                <option value="">Select Stage</option>
                                {mistakeStages.map((stage) => (
                                    <option key={stage.value} value={stage.value}>{stage.label}</option>
                                ))}
                            </select>
                        </div>
                        <textarea
                            className="w-full border rounded-md p-2 mb-4"
                            placeholder="Rejection Reason"
                            value={rejectionReason}
                            onChange={(e) => setRejectionReason(e.target.value)}
                        />
                        <div className="flex justify-end mt-4">
                            <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 font-semibold rounded-sm shadow-md mr-2" onClick={rejectApplication}>Submit</button>
                            <button className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-1 font-semibold rounded-sm shadow-md" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Image Modal */}
            {imageModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6 relative">
                        <FaTimes
                            className="absolute top-4 right-4 cursor-pointer text-xl text-gray-500 hover:text-gray-700"
                            onClick={handleCloseImageModal}
                        />
                        <img src={selectedImage} alt="Preview" className="w-full h-auto rounded-md" />
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>
    );
};

export default PendingApprovals;
